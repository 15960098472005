<template>
  <lidaPage
    :columns="columns"
    :query="stockPage"
    :formColumns="formColumns"
    subTitle="总供应商数"
    :upload="upload"
  >
    <div style="display:flex;align-items: center;width: 700px;">
      <div class="btn">
        <el-button type="primary" size="small" @click="go">库存导入</el-button>
      </div>
      <div>
        注意：库存产品不对外展示 如需要对外销售，需要添加到“我在售的产品”<br />
        添加步骤：1，我要销售-销售中心-我在售的产品-新增-查看库存产品<br />
        2，盘雷总页面左下角快捷“销售中心”
      </div>
      <!-- <div style="margin:0 20px;">
      <el-input v-model="input" placeholder="关键字" @change="inputChange"/>
    </div> -->
    </div>
    <template #goods="scope">
      <div class="g-info" style="padding: 10px 0;">
        <div class="g-icon">
          <img :src="scope.row.src" />
        </div>
        <div class="g-right">
          <div class="g-r-title">
            {{ scope.row.productName }}
            <div class="label" :title="scope.row.brandName">
              {{ scope.row.brandName }}
            </div>
          </div>
          <div class="g-r-data">
            {{ scope.row.barCode || "无" }}
            <span>|</span>
            {{ scope.row.seriesName || "无" }}
            <span>|</span>
            {{ scope.row.skuNumber || "无" }}
          </div>
        </div>
      </div>
    </template>
  </lidaPage>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      input: "",
      arr: [],
      columns: [
        // { label: "产品信息", prop: "goods" },
        { label: "品牌", prop: "brandName" },
        { label: "商品名称", prop: "productName" },
        { label: "系列", prop: "seriesName" },
        { label: "订货号", prop: "articleNo" },
        { label: "库存量", prop: "quantity" },
        { label: "入库时间", prop: "lastInTime" },
      ],
    };
  },
  computed: {
    ...mapState("stockModule", ["options"]),
    formColumns() {
      return [
        {
          type: "date",
          prop: "time",
          label: "入库日期",
        },
        {
          type: "input",
          prop: "keyword",
          label: "关键字",
          placeholder:'请输入关键字'
        },
        {
          type: "select",
          prop: "brandId",
          label: "品牌",
          options: this.options,
          optionsLabel: "title",
          optionsValue: "type",
          placeholder:'请选择品牌'
        },
        {
          type: "input",
          prop: "articleNo",
          label: "订货号",
          placeholder:'请输入订货号'
        },
        {
          type: "input",
          prop: "barCode",
          label: "型号",
          placeholder:'请输入型号'
        },
      ];
    },
  },
  created() {
    this.getOptions();
  },
  methods: {
    ...mapActions("stockModule", [
      "stockPage", //获取库存列表
    ]),
    ...mapActions("purchaseMudule", [
      "listSkuCategoryForFirst", //顶级分类请求
    ]),

    go() {
      this.$router.push({ path: "uploadStock" });
    },
    getOptions() {
      //品牌列表
      this.listSkuCategoryForFirst({}).then((res) => {
        let { data, code } = res.data;
        // console.log(data.data, code);
        if (code === "0" && data.data.length) {
          this.arr=data.data.map(v=>{
          return {
            title:v.categoryName,
            type:v.id
          }

        })
        this.$store.dispatch('stockModule/changeSetting',this.arr)
        // let a= this.$store.state.stockModule.options;

        }
      })

    },
  },
};
</script>

<style scoped lang="less">
.btn {
  margin: 10px 10px;
}
</style>
